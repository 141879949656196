/**
 * 安全巡检执行记录列表
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Select, Option, Message, Table, Page, Modal,Button,Poptip } from "view-design";
import { recordListColumns,
    recordStatus,
    getRecordListService,
    getRecordDataService,
} from '@/service/workflow-service'
import { mapMutations } from "vuex";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Table', Table);
Vue.component('Page', Page);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('Poptip', Poptip);
import defaultAvatar from '@/assets/Image/default-avatar.png'
import { downloadFile } from '@/utils/tools'
const RecordInspectionList = {
    name:'RecordInspectionList',
    data() {
        return {
            recordLoading:false, //loading
            recordListColumns:recordListColumns, //列表显示字段
            defaultAvatar:defaultAvatar,
            recordListArray:{}, //列表数据
            recordStatus:recordStatus, //执行状态
            deviceListHeight:0, //表格高度
            recordListParams:{ //提交的数据集
                tasksName:'',  //任务名称
                flowName:'', //引用流程
                status:'', //任务状态
                taskId:'',
                currentPage: 1,  //当前页
                displayNumber:10 // 显示条数
            }
        }
    },
    created() {
        this.onRecordList().then()
        this.onTableHeight()
    },
    mounted() {
        window.addEventListener('resize', this.onTableHeight)
    },
    methods: {
        ...mapMutations('system',['SET_TASK_ID','SET_TASK_LIST']),
        /**
         * 计算table高度
         */
        onTableHeight(){
            this.deviceListHeight = window.innerHeight - 223
            // this.windowWidth = window.innerWidth
        },
        /**
         * 重置
         */
        onReset() {
            this.recordListParams.tasksName = ''
            this.recordListParams.flowName = ''
            this.recordListParams.status = ''
        },
        /**
         * 搜索
         */
        onSearch(){
            this.onRecordList().then()
        },
        /**
         * 下载验证
         * @param row
         */
        onDownLoad(row) {
            this.onDownLoadRecordData(row).then()
        },
        /**
         * 查看跳转
         * @param row
         */
        onQueryLink(row) {
            this.SET_TASK_ID(row.id)
            this.SET_TASK_LIST(row)
            this.$router.push('/workflow/record/inspection/info')
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.recordListParams.currentPage = number
            this.onRecordList().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.recordListParams.displayNumber = number
            this.onRecordList().then()
        },
        /**
         * 获取执行记录列表
         */
        async onRecordList() {
            try {
                this.recordLoading = true
                this.recordListArray = {}
                let params = {
                    taskName:this.recordListParams.tasksName,
                    flowName:this.recordListParams.flowName,
                    status:this.recordListParams.status,
                    taskId:this.recordListParams.taskId,
                    tasksType:1,
                    size:this.recordListParams.displayNumber,
                    page:this.recordListParams.currentPage
                }
                const { code,data } = await getRecordListService(params)
                if(code === 'SUCCESS'){
                    this.recordListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.recordLoading = false
            }catch (error){
                this.recordLoading = false
                console.log(error)
            }
        },
        /**
         * 下载记录数据
         */
        async onDownLoadRecordData(row) {
            try {
                this.recordLoading = true
                let params = {
                    id:row.id
                }
                const data = await getRecordDataService(params)
                downloadFile(row,data,'excel')
                this.recordLoading = false
            }catch (error){
                this.recordLoading = false
                console.log(error)
            }
        },
    },
    destroyed () {
    }
}
export default RecordInspectionList
