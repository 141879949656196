var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-record-list"},[_c('div',{staticClass:"device-list-search"},[_vm._m(0),_c('div',{staticClass:"device-list-select"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-list"},[_c('span',{staticClass:"label"},[_vm._v("任务名称：")]),_c('Input',{staticStyle:{"width":"160px"},attrs:{"clearable":"","placeholder":"任务名称"},model:{value:(_vm.recordListParams.tasksName),callback:function ($$v) {_vm.$set(_vm.recordListParams, "tasksName", $$v)},expression:"recordListParams.tasksName"}})],1),_c('div',{staticClass:"box-list"},[_c('span',{staticClass:"label"},[_vm._v("执行状态：")]),_c('Select',{staticStyle:{"width":"160px"},attrs:{"clearable":""},model:{value:(_vm.recordListParams.status),callback:function ($$v) {_vm.$set(_vm.recordListParams, "status", $$v)},expression:"recordListParams.status"}},_vm._l((_vm.recordStatus),function(status,key){return _c('Option',{key:key,attrs:{"value":status.value}},[_vm._v(_vm._s(status.label))])}),1)],1),_c('div',{staticClass:"box-list"},[_c('span',{staticClass:"label"},[_vm._v("引用流程：")]),_c('Input',{staticStyle:{"width":"160px"},attrs:{"clearable":"","placeholder":"引用流程"},model:{value:(_vm.recordListParams.flowName),callback:function ($$v) {_vm.$set(_vm.recordListParams, "flowName", $$v)},expression:"recordListParams.flowName"}})],1)]),_c('div',{staticClass:"box"},[_c('Button',{on:{"click":_vm.onReset}},[_vm._v("重置")]),_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.onSearch}},[_vm._v("查询")])],1)])]),_c('div',{staticClass:"device-list"},[_c('Table',{attrs:{"loading":_vm.recordLoading,"height":_vm.deviceListHeight,"stripe":"stripe","columns":_vm.recordListColumns,"data":_vm.recordListArray.content},scopedSlots:_vm._u([{key:"状态",fn:function(ref){
var row = ref.row;
return [(row.status === 2)?_c('font',{staticClass:"yellow"},[_vm._v("进行中")]):_vm._e(),(row.status === 1)?_c('font',{staticClass:"green"},[_vm._v("已完成")]):_vm._e(),(row.status === 0)?_c('font',{staticClass:"grey"},[_vm._v("未完成")]):_vm._e()]}},{key:"执行人",fn:function(ref){
var row = ref.row;
return [_c('Poptip',{attrs:{"placement":"bottom-start"},scopedSlots:_vm._u([(row.executorUser)?{key:"content",fn:function(){return [_c('div',{staticClass:"user-item-box"},[_c('span',{staticClass:"avatar",style:('background-image:url('+row.executorUser.profilePicture+')')}),_c('div',{staticClass:"box-r"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(row.executorUser.userName))]),_c('span',{staticClass:"tel"},[_vm._v(_vm._s(row.executorUser.mobile))])])])]},proxy:true}:null],null,true)},[(row.executorUser)?_c('Button',{staticClass:"user-item"},[_vm._v(" "+_vm._s(row.executorUser.userName))]):_vm._e()],1),_vm._v(" "+_vm._s(!row.executorUser?'/':'')+" ")]}},{key:"开始时间",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.beginTime?row.beginTime.replace(/:..$/, '') : '/')+" ")]}},{key:"完成时间",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.finishTime?row.finishTime.replace(/:..$/, '') : '/')+" ")]}},{key:"操作",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"handle"},[_c('a',{attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.onQueryLink(row)}}},[_vm._v("查看")]),(row.status === 1)?_c('a',{attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.onDownLoad(row)}}},[_vm._v("生成报告")]):_vm._e(),(row.status === 0)?_c('span',{staticClass:"handle-grey"},[_vm._v("生成报告")]):_vm._e()])]}}])}),(_vm.recordListArray.content && _vm.recordListArray.content.length !== 0)?_c('div',{staticClass:"device-list-page"},[_c('Page',{attrs:{"total":_vm.recordListArray.totalSize,"current":_vm.recordListArray.currentPage,"size":"small","show-total":"","show-sizer":"","page-size":_vm.recordListParams.displayNumber},on:{"on-change":_vm.onSearchPage,"on-page-size-change":_vm.onSearchPageSize}})],1):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"title"},[_c('i',{staticClass:"icon"}),_vm._v("作业记录")])}]

export { render, staticRenderFns }